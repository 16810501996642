import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { WelcomeFormSection } from '../../components/WelcomeFormSection';
import { VideoSection } from '../../components/VideoSection';
import { UGCSection } from '../../components/UGCSection';
import { BrandsSection } from '../../components/BrandsSection';
import { ConversionOptimizer } from '../../components/ConversionOptimizer';

export const CreatorsLP = () => {
  const brandsSectionRef = useRef(null);
  const ugcSectionRef = useRef(null);
  const formSectionRef = useRef(null);

  const scrollToBrands = () => {
    if (brandsSectionRef.current) {
      brandsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToUGC = () => {
    if (ugcSectionRef.current) {
      ugcSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToForm = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box sx={{ color: 'var(--white-color)', minHeight: '100vh' }}>
        <Header onScrollToBrands={scrollToBrands} onScrollToUGC={scrollToUGC} />
        <Box ref={formSectionRef}>
          <WelcomeFormSection />
        </Box>
        <VideoSection />
        <Box ref={ugcSectionRef}>
          <UGCSection />
        </Box>
        <Box ref={brandsSectionRef}>
          <BrandsSection />
        </Box>
        <ConversionOptimizer onScrollToForm={scrollToForm} />
        <Footer />
      </Box>
    </>
  );
};
