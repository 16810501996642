import React from 'react';
import { Box, Typography, Button, Container, Grid2 } from '@mui/material';
import logoFinalLevel from '../../assets/images/logo-finalLevel.png'; // Atualize para o caminho correto
import { brands } from '../../utils/brands.utils'; // Importando as marcas

export const BrandsSection = () => {
  const handleRedirect = () => {
    window.open('https://finallevel.com.br/', '_blank');
  };

  return (
    <Box sx={{ backgroundColor: '#906CF4', padding: '50px 0' }}>
      <Container maxWidth="lg">
        <Box sx={{ marginBottom: '40px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ width: { xs: '100%', md: '50%' } }}>
            <img src={logoFinalLevel} alt="Final Level Logo" style={{ maxWidth: '400px', marginBottom: '20px' }} />
          </Box>
          <Box sx={{ width: { xs: '100%', md: '50%' } }}>
            <Typography
              variant="body1"
              sx={{
                color: 'white',
                fontSize: '24px',
                lineHeight: '1.3',
                marginBottom: '20px',
                margin: '0 auto',
              }}
            >
              OmniContent é uma plataforma da Final Level Co, uma content tech especializada no universo jovem que joga
              junto das principais marcas desde 2018.
            </Typography>
          </Box>
        </Box>

        <Grid2 container spacing={4} justifyContent="center" alignItems="center" sx={{ my: '80px' }}>
          {brands.map((brand, index) => (
            <Grid2 item xs={6} sm={4} md={2} key={index} alignItems={'center'}>
              <img src={brand.logo} alt={brand.name} style={{ maxWidth: '100px', height: 'auto' }} />
            </Grid2>
          ))}
        </Grid2>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleRedirect}
            sx={{
              backgroundColor: '#FFEB3B',
              color: 'var(--black-color)',
              borderRadius: '50px',
              padding: '10px 30px',
              textTransform: 'none',
              fontFamily: 'var(--Zumee400)',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#FDD835',
              },
            }}
          >
            CONHEÇA A FINAL LEVEL CO
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
