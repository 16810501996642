import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import logo from '../../assets/images/logo-omnicontent-black.png';

export const Footer = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: '40px 0' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: { xs: 4, md: 0 },
          color: 'var(--black-color)',
          p: 5,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box>
            <img src={logo} alt="logo" style={{ maxWidth: '300px' }} />
          </Box>
          <Typography variant="body1">
            OmniContent é uma plataforma da Final Level Co de videos em escala, uma content tech especializada no
            universo jovem.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
