import * as React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import { CreatorSignUp } from '../pages/CreatorSignUp';
import { CreatorsLP } from '../pages/CreatorsLP';

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route path={'/'} element={<CreatorsLP />} />
      <Route path={'/sign-up'} element={<CreatorSignUp />} />
    </RouterRoutes>
  );
};
