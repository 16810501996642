const REACT_APP_TOKEN = 'FINALLEVELCO';

export const Get = async (url) => {
  try {
    const creator = getCreatorIdFromLocalStorage();

    const response = await fetch(url, {
      headers: {
        Authorization: REACT_APP_TOKEN,
        creator,
      },
    });

    const responseData = await response.json();

    if (response.status !== 200) {
      const error = responseData.message ?? responseData.error;
      throw new Error(error);
    }

    return responseData;
  } catch (error) {
    throw new Error(error);
  }
};

export const Post = async (url, data) => {
  try {
    const creator = getCreatorIdFromLocalStorage();

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: REACT_APP_TOKEN,
        creator,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.message);
    }

    return responseData;
  } catch (error) {
    throw new Error(error);
  }
};

export const Patch = async (url, data) => {
  const creator = getCreatorIdFromLocalStorage();

  const isFormData = data instanceof FormData;
  const headers = {
    Authorization: REACT_APP_TOKEN,
    creator,
  };

  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
    data = JSON.stringify(data);
  }

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: data,
    });

    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(responseData);
    }

    return response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export const Download = async (url) => {
  const creator = getCreatorIdFromLocalStorage();

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: REACT_APP_TOKEN,
        creator,
      },
    });

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData);
    }

    return response.text();
  } catch (error) {
    throw new Error(error);
  }
};

export const Delete = async (url) => {
  const creator = getCreatorIdFromLocalStorage();

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: REACT_APP_TOKEN,
        creator,
      },
      method: 'DELETE',
    });

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData);
    }

    return responseData;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserKey = () => {
  const isDevEnvironment = window.location.hostname.includes('dev');
  return isDevEnvironment ? 'dev_creator' : 'prod_creator';
};

const getCreatorIdFromLocalStorage = () => {
  const storedCreator = localStorage.getItem(getUserKey());
  if (storedCreator) {
    try {
      const creator = JSON.parse(storedCreator);
      return creator.id ? creator.id : null;
    } catch (e) {
      throw new Error(e);
    }
  } else {
    return null;
  }
};
