import React from 'react';
import { Box, Container } from '@mui/material';
import { Header } from '../../components/Header';

export const CreatorSignUp = () => {
  return (
    <>
      <Box sx={{ backgroundColor: 'var(--primary-dark-color)', color: '#FFFFFF', minHeight: '100vh' }}>
        <Container maxWidth="xl">
          <Header />
        </Container>
      </Box>
    </>
  );
};
