import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';

// eslint-disable-next-line react/prop-types
export const ConversionOptimizer = ({ onScrollToForm }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'var(--primary-light-color)',
        padding: '50px 0',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
        maxWidth="xl"
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.5rem', md: '5rem' },
            marginBottom: '20px',
          }}
        >
          AUMENTE A SUA CONVERSÃO EM VENDAS
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '40px',
            fontSize: { xs: '0.875rem', md: '1rem' },
          }}
        >
          Transformamos ideias em vídeos reais, criando conexões genuínas entre marcas e seus públicos.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '20px',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            onClick={onScrollToForm}
            sx={{
              backgroundColor: '#D1FF00',
              color: 'var(--primary-dark-color)',
              padding: '10px 20px',
              fontWeight: 'bold',
              borderRadius: 50,
              fontFamily: 'var(--Zumee400)',
              width: { xs: '100%', md: 'auto' },
              '&:hover': {
                backgroundColor: '#BCE500',
              },
            }}
          >
            QUERO SER CREATOR
          </Button>
          {/* <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF5A00',
              color: '#000',
              padding: '10px 20px',
              fontWeight: 'bold',
              borderRadius: 50,
              fontFamily: 'var(--Zumee400)',
              width: { xs: '100%', md: 'auto' },
              '&:hover': {
                backgroundColor: '#E54F00',
              },
            }}
          >
            QUERO RECEBER UMA PROPOSTA
          </Button> */}
        </Box>
      </Container>
    </Box>
  );
};
